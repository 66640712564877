<template>
  <div>
    <div>Platform: {{ platform }}</div>
    <div>Browser: {{ browser }}</div>
    <div>Version: {{ version }}</div>

    browser detect:
    <div>
      {{ browserDetect }}
    </div>
  </div>
</template>
<script>

export default {
  name: 'Dev',
  props: [],
  components: {
  },
  data: () => ({
  }),
  computed: {
    platform () {
      return this.$store.getters.getPlatform;
    },
    browser () {
      return this.$store.getters.getBrowser;
    },
    version () {
      return process.env.VUE_APP_GIT_HASH;
    },
    browserDetect () {
      return this.$store.getters.getBrowserDetect;
    }
  },
  watch: {
  },
  methods: {
  },
  mounted () {
  }
}
</script>
<style lang="scss" scoped>
</style>
